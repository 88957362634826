<template>
  <div class="modal-container" :class="{ show }">
    <div class="modal create-edit-kid" v-if="activeSchoolYear && form && show">
      <h2>Inscription d'un enfant</h2>
      <h3>{{ activeSchoolYear.name }}</h3>
      <font-awesome-icon :icon="['fas', 'times']" class="close" @click="hideModal" />
      <form @submit.prevent="submitStep0" v-if="step === 0">
        <table v-if="form.weeks.length > 0">
          <tr>
            <th>Jour</th>
            <th
              v-for="(slot, idx) in activeSchoolYear.slots"
              :key="`slot-${idx}`"
            >{{ slot.text }}</th>
          </tr>
          <tr
            v-for="(day, idx) in form.weeks[form.weekIndex]"
            :key="`day-${form.weekIndex}-${idx}`"
          >
            <td>{{ day.date.toLocaleDateString('fr-FR', dayOptions) | capitalize }}</td>
            <td
              v-for="(slot, i) in activeSchoolYear.slots"
              :key="`day-${form.weekIndex}-${idx}-slot-${i}`"
              class="hoverable"
              @click="!day.slots[slot.slug].disabled ? day.slots[slot.slug].value = !day.slots[slot.slug].value : null"
            >
              <input
                type="checkbox"
                class="form"
                :disabled="day.slots[slot.slug].disabled"
                v-model="day.slots[slot.slug].value"
              >
            </td>
          </tr>
        </table>
        <div class="grid">
          <button
            class="form full white"
            @click.prevent="prevWeek"
            :disabled="form.weekIndex === 0"
          >Semaine précédente</button>
          <button
            class="form full white"
            @click.prevent="nextWeek"
            :disabled="form.weekIndex === form.weeks.length - 1"
          >Semaine suivante</button>
        </div>
        <div class="group">
          <select
            v-model="form.shortcut"
            id="shortcuts"
            class="form"
            @change="shortcutAction"
          >
            <option
              value=""
              selected
              hidden
              disabled
            >
              Actions rapides...
            </option>
            <option value="slot--week--all">
              Tout cocher pour cette semaine
            </option>
            <option value="slot--week--no">
              Tout décocher pour cette semaine
            </option>
            <option value="slot--all--all">
              Tout cocher pour l'année
            </option>
            <option value="slot--all--no">
              Tout décocher pour l'année
            </option>
            <option
              v-for="slot in activeSchoolYear.slots"
              :key="`slot--week--${slot.slug}`"
              :value="`slot--week--${slot.slug}`"
            >
              Cocher tous les "{{ slot.text }}" pour cette semaine
            </option>
            <option
              v-for="slot in activeSchoolYear.slots"
              :key="`slot--all--${slot.slug}`"
              :value="`slot--all--${slot.slug}`"
            >
              Cocher tous les "{{ slot.text }}" pour l'année
            </option>
          </select>
        </div>
        <button
          class="form full"
          type="submit"
        >{{ edit ? "Modifier l'inscription de" : 'Inscrire'}} {{ kid.firstname }} {{ kid.lastname }}</button>
      </form>
      <div class="recap" v-else-if="step === 1">
        <template v-if="form.daysToSend.length">
          <div
            v-for="day in form.daysToSend"
            :key="`day-${day.uid}`"
            class="modif-container"
          >
            <span class="date">{{ formatDateStep1(day.uid) }}</span>
            <span
              v-for="slot in day.slots"
              :key="`slot-${day.uid}-${slot.slug}`"
              class="modif"
            >{{ slot.value ? 'Inscription' : 'Désincription' }} à "{{ slot.name }}"</span>
          </div>
        </template>
        <template v-else>
          <span class="modif">Aucune modification ne va être envoyé</span>
        </template>
        <button
          class="form full white"
          @click.prevent="step = 0"
        >Retour à l'inscription</button>
        <button
          class="form full"
          type="submit"
          v-if="form.daysToSend.length"
          @click.prevent="submitStep1"
        >Valider</button>
      </div>
      <button
        class="form full close"
        @click.prevent="hideModal"
      >Fermer</button>
    </div>
  </div>
</template>

<script>
import { subBusinessDays, isBefore, isAfter, format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { mapActions, mapGetters } from 'vuex'
import { getCurrentDateTime } from '@/services/time'

const defaultFormData = {
  weeks: [],
  weekIndex: 0,
  shortcut: '',
  daysToSend: null
}

export default {
  name: 'EnrollKid',
  components: {
  },
  props: {
    kid: {
      type: Object,
      default: null,
    },
    activeSchoolYear: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      step: 0,
      form: null,
      dayOptions: {
        weekday: 'long',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      days: [
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
      ],
      currentDateTime: null,
      edit: false,
    }
  },
  computed: {
    ...mapGetters('settings', ['getTimeLimits']),
    isAdminPage() {
      return this.$route.name === 'Admin'
    },
  },
  methods: {
    ...mapActions('schoolyears', ['enrollKid']),
    showModal() {
      this.form = this.lodash.cloneDeep(defaultFormData)
      this.edit = false
      this.step = 0
      getCurrentDateTime().then(res => {
        this.currentDateTime = new Date(res.setHours(0,0,0,0))
        this.getSlots()
      })
    },
    hideModal(e, skip = false) {
      if (skip || confirm('Voulez vous vraiment quitter ? Rien ne sera sauvegardé.')) {
        this.show = false
        this.form = null
      }
    },
    prevWeek() {
      if (!this.form || this.form.weekIndex === 0) return
      this.form.weekIndex -= 1
    },
    nextWeek() {
      if (!this.form || this.form.weekIndex === this.form.weeks.length - 1) return
      this.form.weekIndex += 1
    },
    shortcutToggle(day, index) {
      if (index === 'all') {
        Object.keys(day.slots).forEach(key => {
          if (!day.slots[key].disabled) {
            day.slots[key].value = true
          }
        })
      } else if (index === 'no') {
        Object.keys(day.slots).forEach(key => {
          if (!day.slots[key].disabled) {
            day.slots[key].value = false
          }
        })
      } else {
        let slot = day.slots[index]
        if (!slot.disabled) {
          slot.value = true
        }
      }
    },
    shortcutAction() {
      let action = this.form.shortcut.split('--')
      if (action[0] === 'slot') {
        let index = Number(action[2])
        if (isNaN(index)) {
          index = action[2]
        }
        if (action[1] === 'all') {
          this.form.weeks.forEach(week => {
            week.forEach(day => {
              this.shortcutToggle(day, index)
            })
          })
        } else if (action[1] === 'week') {
          this.form.weeks[this.form.weekIndex].forEach(day => {
            this.shortcutToggle(day, index)
          })
        }
      }
      this.form.shortcut = ''
    },
    getSlots() {
      let week = []
      let lastDay = 0
      let isSunday = false

      this.activeSchoolYear.enabledDays.forEach((day, idx) => {
        if (day && idx === 0)
          isSunday = true

        if (day && idx > lastDay)
          lastDay = idx
      })

      this.activeSchoolYear.days.forEach((day, idx) => {
        let formattedDay = {
          uid: day.uid,
          date: new Date(day.date.seconds * 1000),
          slots: {} 
        }
        const dateIsInThePast = isBefore(formattedDay.date, this.currentDateTime)
        const timeLimitCreate = isAfter(this.currentDateTime, subBusinessDays(formattedDay.date, this.getTimeLimits.create))
        const timeLimitCancel = isAfter(this.currentDateTime, subBusinessDays(formattedDay.date, this.getTimeLimits.cancel))
        let isDisabled = false

        if (dateIsInThePast) {
          isDisabled = true
        }

        day.slots.forEach(slot => {
          let formattedSlot = {
            name: slot.name,
            slug: slot.slug,
          }
          const schoolYearSlot = this.activeSchoolYear.slots.find(el => el.slug === slot.slug)
          let disableSlotOnly = false
          if(!this.isAdminPage && schoolYearSlot.max && slot.kids.length >= schoolYearSlot.max) {
            disableSlotOnly = true
          }

          if (!this.isAdminPage && timeLimitCreate) {
            isDisabled = true
          }

          if (slot.kids.length > 0 && slot.kids.find(kid => kid === this.kid.uid)) {
            this.edit = true
            formattedSlot.value = true
            formattedSlot.originalValue = true

            if (disableSlotOnly) {
              disableSlotOnly = false
            }

            if (!timeLimitCancel && !dateIsInThePast) {
              isDisabled = false
            }
          } else {
            formattedSlot.value = false
            formattedSlot.originalValue = false
          }

          formattedSlot.disabled = isDisabled ? true : disableSlotOnly ? true : !slot.enabled

          formattedDay.slots[formattedSlot.slug] = formattedSlot
        })
        week.push(formattedDay)

        if (!dateIsInThePast && this.form.weekIndex === 0 && this.form.weeks.length > 0) {
          this.form.weekIndex = this.form.weeks.length
        }
        if (!isSunday && formattedDay.date.getDay() === lastDay) {
          this.form.weeks.push(week)
          week = []
        } else if (isSunday && formattedDay.date.getDay() === 0) {
          this.form.weeks.push(week)
          week = []
        } else if (idx === this.activeSchoolYear.days.length - 1) {
          this.form.weeks.push(week)
          week = []
        }
      })

      this.show = true
    },
    formatDateStep1(uid) {
      return format(new Date(uid), 'eeee d LLLL', { locale: fr })
    },
    submitStep0() {
      let days = []
      this.form.weeks.forEach(week => {
        week.forEach(day => {
          let formattedDay = {
            uid: day.uid,
            slots: []
          }
          Object.keys(day.slots).forEach(slotKey => {
            const slot = day.slots[slotKey]
            if (slot.value !== slot.originalValue) {
              formattedDay.slots.push({
                slug: slot.slug,
                name: slot.name,
                value: slot.value
              })
            }
          })
          if (formattedDay.slots.length > 0) {
            days.push(formattedDay)
          }
        })
      })

      this.form.daysToSend = days
      this.step = 1
    },
    submitStep1() {
      this.enrollKid({ kidUid: this.kid.uid, days: this.form.daysToSend })
      this.hideModal(null, true)
    }
  },
}
</script>

<style lang="scss" scoped>
svg.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

h3 {
  margin-bottom: 10px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;

  div.grid {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      min-width: 420px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px 20px;
    }
  }

  div.group {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  table {
    display: block;
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 80%;
    overflow: auto;
    width: auto;

    @media screen and (min-width: 768px) {
      font-size: 115%;
    }

    th, td {
      text-align: center;
      padding: 10px 15px;

      @media screen and (min-width: 768px) {
        padding: 20px 30px;
      }
    }
    
    th {
      background-color: #509edd;
      color: white;
      font-weight: normal;
    }

    td {
      background-color: rgb(238, 238, 238);
      color: rgb(111, 111, 111);

      &.hoverable:hover {
        background-color: rgb(211, 211, 211);
      }
    }
  }
}

div.recap {

  @media screen and (min-width: 768px) {
    min-width: 300px;
  }

  div.modif-container {
    display: flex;
    flex-direction: column;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    span.date {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  span.modif:not(:last-child) {
    margin-bottom: 5px;
  }
}

button {
  margin: 10px 0 0 !important;

  &.close {
    margin: 10px 0 0 !important;
    background-color: white;
    color: #3490dc;
    border: 1px solid #3490dc;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &.white {
    border: 1px solid #3490dc;
    background-color: white;
    color: #22292f;

    &:hover {
      background-color: #5fa5df;
    }
  }
}
</style>