import { render, staticRenderFns } from "./ListKid.vue?vue&type=template&id=26ef8b66&scoped=true"
import script from "./ListKid.vue?vue&type=script&lang=js"
export * from "./ListKid.vue?vue&type=script&lang=js"
import style0 from "./ListKid.vue?vue&type=style&index=0&id=26ef8b66&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26ef8b66",
  null
  
)

export default component.exports