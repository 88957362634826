<template>
  <div class="kids-container">
    <div class="kids-header">
      <h2>{{ title }}</h2>
      <button
        class="form"
        @click="addKid"
        v-if="!isAdminPage"
      >Inscrire un enfant</button>
      <input
        v-if="isAdminPage"
        v-model="search"
        class="form search"
        type="text"
        placeholder="Rechercher un enfant"
      />
    </div>
    <div class="kids-list" :class="{ isAdmin: isAdminPage }">
      <div class="row titles">
        <div class="cell firstname" @click="setOrder('firstname')">
          <span>Prénom</span>
          <font-awesome-icon
            :icon="['fas', order[0] === 'firstname' ? order[1] === 'ASC' ? 'sort-up' : 'sort-down' : 'sort']"
          />
        </div>
        <div class="cell lastname" @click="setOrder('lastname')">
          <span>Nom</span>
          <font-awesome-icon
            :icon="['fas', order[0] === 'lastname' ? order[1] === 'ASC' ? 'sort-up' : 'sort-down' : 'sort']"
          />
        </div>
        <div class="cell birthday" @click="setOrder('birthday')">
          <span>Date de naissance</span>
          <font-awesome-icon
            :icon="['fas', order[0] === 'birthday' ? order[1] === 'ASC' ? 'sort-up' : 'sort-down' : 'sort']"
          />
        </div>
        <div class="cell grade" @click="setOrder('grade')">
          <span>Classe</span>
          <font-awesome-icon
            :icon="['fas', order[0] === 'grade' ? order[1] === 'ASC' ? 'sort-up' : 'sort-down' : 'sort']"
          />
        </div>
        <div v-if="isAdminPage && getUsers.length" class="cell parent">Parent(s)</div>
        <div v-if="isAdminPage" class="cell actions">Actions</div>
      </div>
      <div
        v-for="(kid, idx) in kidsSorted"
        :key="idx"
        class="row"
        :class="{ verified: kid.verified }"
        @click="enrollKid(kid)"
      >
        <div class="cell firstname">{{ kid.firstname}}</div>
        <div class="cell lastname">{{ kid.lastname }}</div>
        <div class="cell birthday">{{ convertTimestampToString(kid.birthdate.seconds) }}</div>
        <div class="cell grade">{{ grades[Number(kid.grade)] }}</div>
        <div v-if="isAdminPage && getUsers.length" class="cell parents">
          <span
            v-for="parent in kid.parent"
            :key="`parent-${idx}-${parent}`"
            class="parent"
          >{{ getParent(parent) }}</span>
        </div>
        <div v-if="isAdminPage" class="cell actions">
          <export-logs v-if="isAdminPage" :kid="kid" />
          <font-awesome-icon
            v-if="isAdminPage"
            :icon="['fas', 'user-edit']"
            class="edit"
            @click.stop="editKid(kid)"
          />
        </div>
      </div>
    </div>
    <create-edit-kid ref="createEditKid" :kid="kid" />
    <enroll-kid ref="enrollKid" :kid="kid" :active-school-year="getActiveSchoolYear" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CreateEditKid from '@/components/CreateEditKid.vue'
import EnrollKid from '@/components/EnrollKid.vue'
import ExportLogs from '@/components/ExportLogs.vue'

export default {
  name: 'ListKids',
  components: {
    CreateEditKid,
    EnrollKid,
    ExportLogs
  },
  props: {
    kids: {
      type: Array,
      default: () => [],
      required: true
    },
    title: {
      type: String,
      default: () => 'Mes enfants'
    }
  },
  data() {
    return {
      kid: null,
      grades: [
        'Petite Section',
        'Moyenne Section',
        'Grande Section',
        'CP',
        'CE1',
        'CE2',
        'CM1',
        'CM2',
      ],
      order: ['firstname', 'ASC'],
      search: ''
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin', 'getUsers']),
    ...mapGetters('schoolyears', ['getActiveSchoolYear']),
    ...mapGetters('layout', ['getInnerWidth']),
    isAdminPage() {
      return this.$route.name === 'Admin'
    },
    kidsSorted() {
      let kids = this.lodash.cloneDeep(this.kids)

      if (['firstname', 'lastname'].includes(this.order[0])) {
        kids.sort((a, b) => {
          return a[this.order[0]].localeCompare(b[this.order[0]], 'fr', { sensitivity: 'base' }) ||
          a.firstname.localeCompare(b.firstname, 'fr', { sensitivity: 'base' })
        })
      } else if (this.order[0] === 'birthday') {
        kids.sort((a, b) => {
          if (a.birthdate.seconds < b.birthdate.seconds) {
            return -1
          } else if (a.birthdate.seconds > b.birthdate.seconds) {
            return 1
          }
          return 0
        })
      } else if (this.order[0] === 'grade') {
        kids.sort((a, b) => {
          if (a.grade < b.grade) {
            return -1
          } else if (a.grade > b.grade) {
            return 1
          }
          return a.firstname.localeCompare(b.firstname, 'fr', { sensitivity: 'base' })
        })
      }

      if (this.order[1] === 'DESC') {
        kids = kids.reverse()
      }

      if (this.search.length > 0) {
        kids = kids.filter(kid => {
          return kid.firstname.toLowerCase().includes(this.search.toLowerCase()) || kid.lastname.toLowerCase().includes(this.search.toLowerCase())
        });
      }

      return kids
    },
    totalPages() {
      return this.kidsPagination.length
    }
  },
  methods: {
    ...mapActions('schoolyears', ['fetchActiveSchoolYear']),
    editKid(kid) {
      this.kid = kid
      this.$nextTick(() => {
        this.$refs.createEditKid.showModal()
      })
    },
    addKid() {
      this.kid = null
      this.$nextTick(() => {
        this.$refs.createEditKid.showModal()
      })
    },
    enrollKid(kid) {
      if (!kid.verified) return

      this.kid = kid
      this.$nextTick(() => {
        this.$refs.enrollKid.showModal()
      })
    },
    pad(s) {
      return (s < 10) ? '0' + s : s
    },
    convertTimestampToString(date) {
      date = new Date(date * 1000)
      return [
        this.pad(date.getDate()),
        this.pad(date.getMonth()+1),
        date.getFullYear(),
      ].join('/')
    },
    prevPage() {
      if (this.pageIndex > 0)
        this.pageIndex -= 1
    },
    nextPage() {
      if (this.pageIndex < this.totalPages - 1)
        this.pageIndex += 1
    },
    getParent(uid) {
      const parent = this.getUsers.find(parent => parent.uid === uid)
      return `${parent.firstname} ${parent.lastname}`
    },
    setOrder(key) {
      if (this.order[0] === key) {
        this.$set(this.order, 1, this.order[1] == 'ASC' ? 'DESC' : 'ASC')
      } else {
        this.order[0] = key
        this.$set(this.order, 1, 'ASC')
      }
    }
  },
  created() {
    this.fetchActiveSchoolYear()
  }
}
</script>

<style lang="scss" scoped>
div.kids-container {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  div.kids-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid lightgray;

    @media screen and (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    h2 {
      font-size: 20px;
    }

    button {
      margin: 10px 0 0;

      @media screen and (min-width: 600px) {
        margin: 0;
      }
    }

    .search {
      margin: 10px 0 0;

      @media screen and (min-width: 600px) {
        margin: 0;
        max-width: 300px;
      }
    }
  }

  div.kids-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 600px;

    div.row {
      display: grid;
      padding: 2px;
      margin: 3px 0;
      cursor: pointer;
      flex-wrap: wrap;
      border-radius: 5px;
      grid-template-columns: 60% 40%;
      background-color: rgba(255, 170, 0, 0.2);

      @media screen and (min-width: 700px) {
        margin: 0;
        padding: 5px 0;
        border-radius: 0;
        align-items: center;
      }

      &:not(.titles):hover {
        background-color: lightgray
      }

      &.titles {
        display: none;
        background-color: transparent;

        @media screen and (min-width: 700px) {
          display: grid;

          .cell {
            font-weight: bold !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      .cell {
        &.firstname, &.lastname {
          font-weight: bold;
        }
        
        &.parents {
          display: flex;
          flex-direction: column;
        }

        &.actions {
          display: flex;
          align-items: center;

          .edit {
            margin-right: 10px;
          }
        }

        @media screen and (min-width: 700px) {
          padding: 0 5px;

          &:not(:last-of-type) {
            border-right: 1px solid lightgray;
          }

          &.firstname, &.lastname {
            font-weight: normal;
          }

          &.birthday {
            text-align: center;
          }

          &.actions {
            justify-content: flex-end;

            .edit {
              margin-left: 10px;
              margin-right: 0;
            }
          }
        }
      }

      &.verified {
        background-color: hsla(120, 93%, 79%, 0.2)
      }
    }

    @media screen and (min-width: 700px) {
      div.row {
        grid-template-columns: 30% 30% 20% 20%;
      }

      &.isAdmin div.row {
        grid-template-columns: 20% 20% 17% 13% 20% 10%;
      }
    }
  }
}
</style>