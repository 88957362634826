<template>
  <div class="modal-container" :class="{ show }">
    <div class="modal create-edit-kid">
      <h2 v-if="kid">Modifier un enfant</h2>
      <h2 v-else>Inscrire un enfant</h2>
      <font-awesome-icon :icon="['fas', 'times']" class="close" @click="hideModal" />
      <form @submit="submit">
        <div class="group parents" v-if="kid && isAdmin && isAdminPage">
          <label
            class="form"
          >Parents</label>
          <multi-select
            v-if="getUsers.length"
            :options="formattedUsers"
            :selected-options="kidInputs.parents"
            placeholder="Choisissez des parents"
            @select="onSelect">
          </multi-select>
          <span class="form">{{ inputsError.parents }}</span>
        </div>
        <div class="group">
          <label
            for="kidFirstname"
            class="form"
          >Prénom</label>
          <input
            type="text"
            class="form"
            :class="{ error: inputsError.firstname }"
            id="kidFirstname"
            placeholder="john"
            v-model.trim="kidInputs.firstname"
            :disabled="isDisabled"
            @change="inputsError.firstname = null"
          />
          <span class="form">{{ inputsError.firstname }}</span>
        </div>
        <div class="group">
          <label
            for="kidLastname"
            class="form"
          >Nom</label>
          <input
            type="text"
            class="form"
            :class="{ error: inputsError.lastname }"
            id="kidLastname"
            placeholder="doe"
            v-model.trim="kidInputs.lastname"
            :disabled="isDisabled"
            @change="inputsError.lastname = null"
          />
          <span class="form">{{ inputsError.lastname }}</span>
        </div>
        <div class="group">
          <label
            for="kidBirthdate"
            class="form"
          >Date de naissance</label>
          <input
            type="date"
            class="form"
            :class="{ error: inputsError.birthdate }"
            id="kidBirthdate"
            v-model.trim="kidInputs.birthdate"
            :disabled="isDisabled"
            :min="minBirthday"
            :max="maxBirthday"
            @change="inputsError.birthdate = null"
          />
          <span class="form">{{ inputsError.birthdate }}</span>
        </div>
        <div class="group">
          <label
            for="kidGrade"
            class="form"
          >Classe</label>
          <select
            v-model="kidInputs.grade"
            id="kidGrade"
            class="form"
          >
            <option :value="0">Petite Section</option>
            <option :value="1">Moyenne Section</option>
            <option :value="2">Grande Section</option>
            <option :value="3">CP</option>
            <option :value="4">CE1</option>
            <option :value="5">CE2</option>
            <option :value="6">CM1</option>
            <option :value="7">CM2</option>
          </select>
        </div>
        <div class="group" v-if="isAdmin && isAdminPage">
          <label
            for="kidVerified"
            class="form"
          >Validé</label>
          <select
            v-model="kidInputs.verified"
            id="kidVerified"
            class="form"
          >
            <option :value="true">Oui</option>
            <option :value="false">Non</option>
          </select>
        </div>
        <div class="group delete" v-if="kid && isAdmin && isAdminPage">
          <button
            class="form danger full delete"
            @click="deleteKidConfirm()"
            @click.prevent
          >Supprimer l'enfant</button>
        </div>
        <button
          class="form full"
          type="submit"
        >{{ this.kid ? 'Modifier' : 'Ajouter' }}</button>
        <button
          class="form full close"
          @click="hideModal"
          @click.prevent
        >Fermer</button>
      </form>
    </div>
  </div>
  
</template>

<script>
import { sub, format } from 'date-fns'
import { mapActions, mapGetters } from 'vuex'
import { MultiSelect } from 'vue-search-select'

export default {
  name: 'CreateEditKid',
  components: {
    MultiSelect
  },
  props: {
    kid: {
      default: null,
    },
  },
  data() {
    return {
      kidInputs: {
        firstname: null,
        lastname: null,
        birthdate: null,
        parents: [],
        grade: 0,
        verified: false
      },
      inputsError: {
        firstname: null,
        lastname: null,
        birthdate: null,
        parents: null,
      },
      dispatchName: 'fetchUserKids',
      show: false,
    }
  },
  computed: {
    ...mapGetters('user', ['isAdmin', 'getUsers']),
    isDisabled() {
      if (this.kid && this.$route.path === '/')
        return true
      return false
    },
    isAdminPage() {
      return this.$route.name === 'Admin'
    },
    minBirthday() {
      return format(sub(new Date(), { years: 18 }), 'yyyy-MM-dd')
    },
    maxBirthday() {
      return format(new Date(), 'yyyy-MM-dd')
    },
    formattedUsers() {
      return this.getUsers.map(user => ({
        value: user.uid,
        text: `${user.firstname} ${user.lastname}`
      }))
    }
  },
  methods: {
    ...mapActions('kids', ['createKid', 'updateKid', 'deleteKid']),
    showModal() {
      if (this.kid) {
        this.kidInputs.firstname = this.kid.firstname
        this.kidInputs.lastname = this.kid.lastname
        this.kidInputs.birthdate = this.convertTimestampToString(this.kid.birthdate.seconds)
        this.kidInputs.grade = this.kid.grade
        this.kidInputs.verified = this.kid.verified
        this.kidInputs.parents = []
        if (this.isAdminPage) {
          this.kid.parent.forEach(parent => {
            const user = this.getUsers.find(el => el.uid === parent)
            this.kidInputs.parents.push({
              value: user.uid,
              text: `${user.firstname} ${user.lastname}`
            })
          });
        }
      }
      this.show = true
    },
    hideModal() {
      this.show = false
      this.kidInputs.firstname = null
      this.kidInputs.lastname = null
      this.kidInputs.birthdate = null
      this.kidInputs.parents = []
      this.kidInputs.grade = 0
      this.inputsError.firstname = null
      this.inputsError.lastname = null
      this.inputsError.birthdate = null
      this.inputsError.parents = null
    },
    submit(e) {
      e.preventDefault()

      let error = false
      if (!this.kidInputs.firstname) {
        this.inputsError.firstname = 'Ce champ est obligatoire'
        error = true
      }
      if (!this.kidInputs.lastname) {
        this.inputsError.lastname = 'Ce champ est obligatoire'
        error = true
      }
      if (!this.kidInputs.birthdate) {
        this.inputsError.birthdate = 'Ce champ est obligatoire'
        error = true
      }
      if (this.isAdminPage && this.kidInputs.parents.length < 1) {
        this.inputsError.parents = 'Ce champ est obligatoire'
        error = true
      }

      if (error) {
        return
      }

      if (this.kid) {
        let kid;
        if (this.isDisabled) {
          kid = {
            grade: Number(this.kidInputs.grade),
            verified: false,
          }
        } else if (this.isAdmin) {
          kid = {
            firstname: this.kidInputs.firstname,
            lastname: this.kidInputs.lastname,
            grade: Number(this.kidInputs.grade),
            birthdate: new Date(this.kidInputs.birthdate),
            verified: this.kidInputs.verified,
            parent: this.kidInputs.parents.map(parent => parent.value)
          }
        }
        this.updateKid({
          data: {
            uid: this.kid.uid,
            kid
          },
          dispatchName: this.dispatchName
        })
      } else {
        this.createKid({
          form: {
            firstname: this.kidInputs.firstname,
            lastname: this.kidInputs.lastname,
            grade: Number(this.kidInputs.grade),
            birthdate: new Date(this.kidInputs.birthdate)
          },
          dispatchName: this.dispatchName
        })
      }

      this.hideModal()
    },
    deleteKidConfirm() {
      if (window.confirm(`Voulez vous vraiment supprimer l'enfant ${this.kidInputs.firstname} ${this.kidInputs.lastname} ?`)) {
        this.deleteKid({
          uid: this.kid.uid,
          dispatchName: this.dispatchName
        })
        this.hideModal()
      }
    },
    pad(s) {
      return (s < 10) ? '0' + s : s
    },
    convertTimestampToString(date) {
      date = new Date(date * 1000)
      return [
        date.getFullYear(),
        this.pad(date.getMonth()+1),
        this.pad(date.getDate())
      ].join('-')
    },
    onSelect (items) {
      this.kidInputs.parents = items
      this.inputsError.parents = null
    },
  },
  mounted() {
    if (this.isAdmin && this.isAdminPage) {
      this.dispatchName = 'fetchAllKids'
    }
  }
}
</script>

<style lang="scss" scoped>
svg.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

form {
  width: 350px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    width: 420px;
    display: grid;
    grid-template-columns: 200px 200px;
    grid-gap: 10px 20px;
  }

  div.group {
    &.delete {
      align-self: end;
    }

    &.parents {
      grid-column: 1 / 3;
    }
  }

  button {
    margin: 10px 0 0;

    &.delete {
      margin: 0 0 .5rem;
    }

    &.close {
      margin: 10px 0 0 !important;
      background-color: white;
      color: #3490dc;
      border: 1px solid #3490dc;

      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    
    @media screen and (min-width: 768px) {
      grid-column: span 2;
    }
  }
}
</style>