<template>
  <button
    v-if="kid == null"
    class="form"
    @click="exportAll"
  >Export historique</button>
  <font-awesome-icon v-else :icon="['fas', 'file-export']" class="edit" @click.stop="exportKid()" />
</template>

<script>
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import { logsCollection } from '@/firebase'
import XLSX from 'xlsx'

export default {
  name: 'ExportLogs',
  props: {
    kid: {
      default: null,
    },
  },
  computed: {
    ...mapGetters('kids', ['getKids']),
    ...mapGetters('user', ['getUsers']),
  },
  methods: {
    exportAll() {
      logsCollection.get().then(this.exportLogs)
    },
    exportKid() {
      logsCollection.where('kid', '==', this.kid.uid)
        .get().then(this.exportLogs)
    },
    exportLogs(querySnapshot) {
      let logs = []
      querySnapshot.forEach(doc => {
        logs.push(doc.data())
      });
      if (logs.length < 1) {
        this.$toasted.error('Aucune donnée disponible.', {
          position: "bottom-center",
          icon: {
            name: 'fas fa-file-export'
          },
          duration: 5000
        })
        return
      }
      logs = logs.sort((a, b) => {
        return a.date.seconds - b.date.seconds
          || a.year.localeCompare(b.year, 'fr', { sensitivity: 'base' })
          || a.day.localeCompare(b.day, 'fr', { sensitivity: 'base' })
      })
      let rows = []
      rows.push([
        'Date',
        'Heure',
        'Année scolaire',
        'Jour',
        'Créneau',
        'Prénom utilisateur',
        'Nom utilisateur',
        'Prénom enfant',
        'Nom enfant',
        'Évènement'
      ])
      logs.forEach(log => {
        const user = this.getUsers.find(user => user.uid === log.user)
        const kid = this.getKids.find(kid => kid.uid === log.kid)
        const date = new Date(log.date.seconds * 1000)
        const day = log.day.split('-')
        rows.push([
          `${format(date, 'dd/MM/yyyy')}`,
          `${format(date, 'HH:mm:ss')}`,
          `${log.year}`,
          `${day[2]}/${day[1]}/${day[0]}`,
          `${log.slot}`,
          `${user.firstname}`,
          `${user.lastname}`,
          `${kid.firstname}`,
          `${kid.lastname}`,
          `${log.status ? 'Inscription' : 'Désinscription'}`,
        ])
      })

      let columnSizes = []
      rows.forEach(row => {
        row.forEach((el, i) => {
          if (columnSizes[i]) {
            if (el.length > columnSizes[i]) {
              columnSizes[i] = el.length
            }
          } else {
            columnSizes[i] = el.length
          }
        })
      })


      let wb = XLSX.utils.book_new()
      const ws = XLSX.utils.aoa_to_sheet(rows)
      ws['!cols'] = columnSizes.map(el => ({wch: el}))
      XLSX.utils.book_append_sheet(wb, ws, 'logs')
      const wopts = { bookType:'xlsx', bookSST:false, type:'array' }
      const wbout = XLSX.write(wb, wopts)
      const blob = new Blob([wbout],{type:"application/octet-stream"})

      let filename = 'historique'

      if (this.kid) {
        filename += `-${this.kid.firstname}-${this.kid.lastname}`
      }

      let link = document.createElement("a")
      link.setAttribute("href", window.URL.createObjectURL(blob))
      link.setAttribute("class", 'export-link')
      link.setAttribute("download", `${filename}.xlsx`)
      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
    }
  }
}
</script>